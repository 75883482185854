import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { withRouterHOC } from '@utils/withRouterHOC';

import Header from '@modules/header/header';
import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

export class AttendanceLog extends Component {
	state = {
		user: {},
		logs: null,
	};
	componentDidMount = async () => {
		const token = await localStorage.getItem('token');
		if (token) {
			const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/student/profile`, {
				headers: {
					'X-Token': JSON.parse(token),
					'content-type': 'application/json',
				},
			});

			const res = await axios.get(`${process.env.REACT_APP_API_URL}/student/student-attendance`, {
				headers: {
					'X-Token': JSON.parse(token),
					'content-type': 'application/json',
				},
			});

			this.setState({
				user: data.data.user,
				logs: res.data.data.logs,
			});
		} else {
			this.props.router.navigate('/login');
		}
	};

	render() {
		const columns = [
			{
				field: 'createdAt',
				headerName: 'Date and Time',
				width: 200,
				editable: false,
			},
			{
				field: 'message',
				headerName: 'Message',
				width: 300,
				editable: false,
			},
		];

		let rows = [];
		if (this.state.logs) {
			rows = this.state.logs.map((log) => {
				return {
					id: log.id,
					createdAt: moment(log.createdAt).format('MM/DD/YYYY hh:mm A'),
					message: log.message,
				};
			});
		}

		return (
			<div>
				<Header />
				<Box sx={{ width: '100%', margin: '2rem', fontWeight: 700 }}>Attendance Logs</Box>
				<div>
					<Box sx={{ height: 400, margin: '2rem' }}>
						<DataGrid
							slots={{ toolbar: GridToolbar }}
							sx={{
								textAlign: 'center',
							}}
							rows={rows}
							columns={columns}
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 5,
									},
								},
							}}
							pageSizeOptions={[15]}
							disableRowSelectionOnClick
						/>
					</Box>
				</div>
			</div>
		);
	}
}

export default withRouterHOC(AttendanceLog);
