import React, { useState, useEffect } from 'react';
import moment from 'moment';

const DateDisplay = ({ format }) => {
	const [date, setDate] = useState();
	function checkDate() {
		const date = moment().format(format ? format : 'MMMM DD YYYY');
		setDate(date);
	}

	setInterval(checkDate, 1000);
	return (
		<div className="date-text">{date ? date : moment().format(format ? format : 'MM-DD-YYYY')}</div>
	);
};

export default DateDisplay;