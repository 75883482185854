import React, { Component } from 'react';
import axios from 'axios';
import { withRouterHOC } from '@utils/withRouterHOC';

import { TextField, Button, Alert, AlertTitle } from '@mui/material';
import './login.css';

export class LoginPage extends Component {
	state = {
		error: '',
		form: this.getDefaultFormValues(),
	};

	getDefaultFormValues() {
		return {
			email: '',
			password: '',
		};
	}

	onFormUpdate(attr, value) {
		const form = { ...this.state.form };

		form[attr] = value;
		this.setState({
			form,
		});
	}

	submitLogin = async () => {
		try {
			this.setState({
				error: '',
			});
			const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/login`, this.state.form);

			await localStorage.setItem('token', JSON.stringify(data.data.token));

			const user = data.data.user;

			if (user.role === 2) {
				this.props.router.navigate('/student/logs');
			} else if (user.role === 1) {
				this.props.router.navigate('/admin/student');
			}
		} catch (e) {
			if (e.response.data) {
				this.setState({
					error: e.response.data.errorMessage,
				});
			}
		}
	};

	render() {
		return (
			<div className='login-page'>
				<div className='form-container'>
					<div className='form-field'>
						<TextField
							id='outlined-basic'
							label='Email'
							variant='outlined'
							value={this.state.form.email}
							onChange={(e) => {
								this.onFormUpdate('email', e.target.value);
							}}
							fullWidth
						/>
					</div>

					<div className='form-field'>
						<TextField
							id='outlined-basic'
							label='Password'
							variant='outlined'
							value={this.state.form.password}
							onChange={(e) => {
								this.onFormUpdate('password', e.target.value);
							}}
							fullWidth
							type='password'
						/>
					</div>

					{this.state.error && (
						<div className='form-field'>
							<Alert sx={{ textAlign: 'left' }} severity='error'>
								<AlertTitle>Error</AlertTitle>
								{this.state.error}
							</Alert>
						</div>
					)}

					<Button
						variant='contained'
						onClick={() => {
							this.submitLogin();
						}}
					>
						Submit
					</Button>
				</div>
			</div>
		);
	}
}

export default withRouterHOC(LoginPage);
