import React from 'react';
import { Outlet } from 'react-router-dom';

import '@elastic/eui/dist/eui_theme_light.css';
import './App.css';

const App = () => {
	return (
		<div className='app'>
			<Outlet />
		</div>
	);
};

export default App;
