import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { withRouterHOC } from '@utils/withRouterHOC';

import Header from '@modules/header/header';
import { Box, Button, IconButton } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

export class StudentListPage extends Component {
	state = {
		students: [],
	};

	componentDidMount = async () => {
		const token = await localStorage.getItem('token');
		if (token) {
			const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admin/students`, {
				headers: {
					'X-Token': JSON.parse(token),
					'content-type': 'application/json',
				},
			});

			this.setState({
				students: data.data.users.rows,
			});
		} else {
			this.props.router.navigate('/login');
		}
	};

	render() {
		const columns = [
			{
				field: 'id',
				headerName: 'System ID',
				width: 100,
				editable: false,
			},
			{
				field: 'first_name',
				headerName: 'First Name',
				width: 150,
				editable: false,
			},
			{
				field: 'middle_name',
				headerName: 'Middle Name',
				width: 150,
				editable: false,
			},
			{
				field: 'last_name',
				headerName: 'Last Name',
				width: 150,
				editable: false,
			},
			{
				field: 'email',
				headerName: 'Email',
				width: 150,
				editable: false,
			},
			{
				field: 'student_id',
				headerName: 'Student ID',
				width: 150,
				editable: false,
			},
			{
				field: 'code',
				headerName: 'RFID Code',
				width: 150,
				editable: false,
			},
			{
				field: 'grade_level',
				headerName: 'Grade Level',
				width: 150,
				editable: false,
			},
			{
				field: 'section',
				headerName: 'Section',
				width: 150,
				editable: false,
			},
			{
				field: 'emergency_name',
				headerName: 'Emergency Contact Name',
				width: 200,
				editable: false,
			},
			{
				field: 'emergency_number',
				headerName: 'Emergency Contact Number',
				width: 200,
				editable: false,
			},
			{
				field: 'action',
				headerName: '',
				width: 70,
				disableClickEventBubbling: true,
				valueGetter: (params) => params.row.id,
				renderCell: (params) => (
					<IconButton
						aria-label='edit'
						onClick={(event) => {
							event.ignore = true;
							this.props.router.navigate(`edit/${params.formattedValue}`);
						}}
					>
						<EditIcon />
					</IconButton>
				),
			},
		];

		let rows = [];
		if (this.state.students) {
			rows = this.state.students.map((student) => {
				return {
					id: student.id,
					student: student.id,
					first_name: student.first_name,
					middle_name: student.middle_name,
					last_name: student.last_name,
					student_id: student.student_id,
					grade_level: student.grade_level,
					section: student.section,
					email: student.email,
					code: student.code,
					emergency_name: student.emergency_name,
					emergency_number: student.emergency_number,
				};
			});
		}

		return (
			<div>
				<Header />
				<Box sx={{ width: '100%', margin: '2rem', fontWeight: 700 }}>Student List</Box>
				<Button
					onClick={() => {
						this.props.router.navigate('new');
					}}
					variant='contained'
				>
					Add
				</Button>
				<div>
					<Box sx={{ height: 700, margin: '2rem' }}>
						<DataGrid
							slots={{ toolbar: GridToolbar }}
							sx={{
								textAlign: 'center',
							}}
							rows={rows}
							columns={columns}
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 50,
									},
								},
							}}
							pageSizeOptions={[50]}
							disableRowSelectionOnClick
						/>
					</Box>
				</div>
			</div>
		);
	}
}

export default withRouterHOC(StudentListPage);
