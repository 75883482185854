import React, { Component } from 'react';
import { withRouterHOC } from '@utils/withRouterHOC';
import axios from 'axios';

import { Box, Button, TextField } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';

import './edit.css';

export class EditStudentPage extends Component {
	state = {
		form: this._getDetaultFormValues(),
	};

	componentDidMount = async () => {
		const token = await localStorage.getItem('token');
		if (token) {
			if (this.props.router.params.id) {
				const { data } = await axios.get(
					`${process.env.REACT_APP_API_URL}/admin/student/${this.props.router.params.id}`,
					{
						headers: {
							'X-Token': JSON.parse(token),
							'content-type': 'application/json',
						},
					}
				);

				this.setState({
					form: this._getDetaultFormValues(data.data.user),
				});
			}
		} else {
			this.props.router.navigate('/login');
		}
	};

	_getDetaultFormValues(data = {}) {
		return {
			first_name: data.first_name ? data.first_name : '',
			middle_name: data.middle_name ? data.middle_name : '',
			last_name: data.last_name ? data.last_name : '',
			image_url_binary: data.image_url_binary ? data.image_url_binary : '',
			image_url: data.image_url ? data.image_url : '',
			student_id: data.student_id ? data.student_id : '',
			grade_level: data.grade_level ? data.grade_level : '',
			section: data.section ? data.section : '',
			email: data.email ? data.email : '',
			code: data.code ? data.code : '',
			line_1: data.line_1 ? data.line_1 : '',
			line_2: data.line_2 ? data.line_2 : '',
			city: data.city ? data.city : '',
			province: data.province ? data.province : '',
			emergency_name: data.emergency_name ? data.emergency_name : '',
			emergency_number: data.emergency_number ? data.emergency_number : '',
			role: data.role ? data.role : 2,
		};
	}

	_onFormUpdate(attr, value) {
		const form = { ...this.state.form };

		form[attr] = value;
		this.setState({
			form,
		});
	}

	_handleImageUpload = (e) => {
		var file = e.target.files[0];
		this._getBase64(file, async (result) => {
			this._onFormUpdate('image_url_binary', result);
		});
	};

	_getBase64(file, cb) {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result);
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	_submit = async () => {
		const token = await localStorage.getItem('token');
		try {
			if (this.props.router.params.id) {
				await axios.put(
					`${process.env.REACT_APP_API_URL}/admin/student/${this.props.router.params.id}`,
					{
						...this.state.form,
					},
					{
						headers: {
							'X-Token': JSON.parse(token),
							'content-type': 'application/json',
						},
					}
				);
			} else {
				await axios.post(
					`${process.env.REACT_APP_API_URL}/admin/student`,
					{
						...this.state.form,
					},
					{
						headers: {
							'X-Token': JSON.parse(token),
							'content-type': 'application/json',
						},
					}
				);
			}
		} catch (e) {}
	};

	render() {
		const imageUrl = this.state.form.image_url_binary ? this.state.form.image_url_binary : this.state.form.image_url;
		return (
			<Box sx={{ margin: '2rem', fontWeight: 700 }}>
				<div className='form-field'>
					<div className='display-image-container'>
						{imageUrl ? <img src={imageUrl} className='display-image' /> : null}
					</div>
					<div>
						<Button variant='contained' component='label' startIcon={<UploadIcon />}>
							Upload photo
							<input type='file' accept='image/*' hidden onChange={this._handleImageUpload} />
						</Button>
					</div>
					<TextField
						id='outlined-basic'
						label='Image URL'
						variant='outlined'
						value={this.state.form.image_url}
						onChange={(e) => {
							this._onFormUpdate('image_url', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='First Name'
						variant='outlined'
						value={this.state.form.first_name}
						onChange={(e) => {
							this._onFormUpdate('first_name', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='Middle Name'
						variant='outlined'
						value={this.state.form.middle_name}
						onChange={(e) => {
							this._onFormUpdate('middle_name', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='Last Name'
						variant='outlined'
						value={this.state.form.last_name}
						onChange={(e) => {
							this._onFormUpdate('last_name', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='Student ID'
						variant='outlined'
						value={this.state.form.student_id}
						onChange={(e) => {
							this._onFormUpdate('student_id', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='Grade Level'
						variant='outlined'
						value={this.state.form.grade_level}
						onChange={(e) => {
							this._onFormUpdate('grade_level', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='Section'
						variant='outlined'
						value={this.state.form.section}
						onChange={(e) => {
							this._onFormUpdate('section', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='Email'
						variant='outlined'
						value={this.state.form.email}
						onChange={(e) => {
							this._onFormUpdate('email', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='Code'
						variant='outlined'
						value={this.state.form.code}
						onChange={(e) => {
							this._onFormUpdate('code', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='Address Line 1'
						variant='outlined'
						value={this.state.form.line_1}
						onChange={(e) => {
							this._onFormUpdate('line_1', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='Address Line 2'
						variant='outlined'
						value={this.state.form.line_2}
						onChange={(e) => {
							this._onFormUpdate('line_2', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='City'
						variant='outlined'
						value={this.state.form.city}
						onChange={(e) => {
							this._onFormUpdate('city', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='Province'
						variant='outlined'
						value={this.state.form.province}
						onChange={(e) => {
							this._onFormUpdate('province', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='Emergency Contact Name'
						variant='outlined'
						value={this.state.form.emergency_name}
						onChange={(e) => {
							this._onFormUpdate('emergency_name', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='Emergency Contact Number'
						variant='outlined'
						value={this.state.form.emergency_number}
						onChange={(e) => {
							this._onFormUpdate('emergency_number', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<div className='form-field'>
					<TextField
						id='outlined-basic'
						label='Role'
						variant='outlined'
						value={this.state.form.role}
						onChange={(e) => {
							this._onFormUpdate('role', e.target.value);
						}}
						fullWidth
					/>
				</div>

				<Button
					variant='contained'
					onClick={() => {
						this._submit();
					}}
				>
					Submit
				</Button>
			</Box>
		);
	}
}

export default withRouterHOC(EditStudentPage);
