import React, { useState, useEffect } from 'react';
import moment from 'moment';

const TimeDisplay = ({ format }) => {
	const [time, setTime] = useState();
	function checkTime() {
		const time = moment().format(format ? format : 'hh:mm A');
		setTime(time);
	}

	setInterval(checkTime, 1000);
	return (
		<div className="time-text">{time ? time : moment().format(format ? format : 'hh:mm A')}</div>
	);
};

export default TimeDisplay;