import React, { useEffect } from 'react';
import useWebSocket from 'react-use-websocket';

const StudentScan = ({ onScan }) => {
	const WS_URL = process.env.REACT_APP_DEVICE_WS_URL;
	const { lastJsonMessage } = useWebSocket(WS_URL, {
		onOpen: () => {
			console.log('WebSocket connection established.');
		},
		retryOnError: true,
		shouldReconnect: () => true,
	});

	useEffect(() => {
		if (lastJsonMessage !== null) {
			onScan(lastJsonMessage);
		}
	}, [lastJsonMessage]);

	return <React.Fragment />;
};

export default StudentScan;
