import { configureStore } from '@reduxjs/toolkit';

// import usersReducer from "middleware/reducers/Users";
// import logsReducer from "middleware/reducers/Logs";

// import studentReducer from 'middleware/reducers/student';

const store = configureStore(
	//   rootReducer,
	//   compose(
	//     applyMiddleware(...middleWare),
	//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	//   )
	{
		reducer: {
			// users: usersReducer,
			// student: studentReducer,
		},
	}
);

export default store;
