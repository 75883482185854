import React, { Component } from 'react';
import './header.css';

export class Header extends Component {
	logoutUser() {
		localStorage.removeItem('token');
		this.props.router.navigate('/login');
	}

	render() {
		return (
			<div className='student-header-container'>
				{/* <Grid container alignItems="center"> */}
				{/* <Grid item xs>
						<span className="student-header-student-name">STUDENT NAME</span>
					</Grid> */}

				{/* <Grid item>
						<TextButton label="LOGS" style="rectangle-two" />
					</Grid> */}

				{/* </Grid> */}
				<div className='right-side-buttons'>
					<div
						className='action-button'
						onClick={() => {
							this.logoutUser();
						}}
					>
						Logout
					</div>
				</div>
			</div>
		);
	}
}

export default Header;
