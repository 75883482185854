import React from 'react';
import { Routes, Route } from 'react-router';

import App from '@pages/App';
import Landing from '@pages/static/landing';

import DeviceAttendanceLogPage from '@pages/device/AttendanceLog';

import LoginPage from '@pages/auth/login';

import StudentAttendanceLogPage from '@pages/student/AttendanceLog';

import StudentEditFormPage from '@pages/admin/student/edit';
import StudentListPage from '@pages/admin/student/list';
const routes = (
	<Routes>
		<Route element={<App />}>
			<Route element={<LoginPage />} path='/login'></Route>
			<Route element={<StudentAttendanceLogPage />} path='/student/logs'></Route>
			<Route element={<DeviceAttendanceLogPage />} path='/device/attendance'></Route>
			<Route element={<StudentEditFormPage />} path='/admin/student/edit/:id'></Route>
			<Route element={<StudentEditFormPage />} path='/admin/student/new'></Route>
			<Route element={<StudentListPage />} path='/admin/student'></Route>
			<Route element={<LoginPage />} path='/'></Route>
		</Route>
	</Routes>
);

export default routes;
